import { Col, Container, Row } from "react-bootstrap";
import { Blob_Base_Url, GetCartItem } from "../../utils/static";

const GetItem = (img, cat = "") => ({
	img,
	title: cat,
	cat: `/search?category=${cat}`,
});
export const SmallCategory = () => {
	const items = [
		GetItem("https://i5.walmartimages.com/dfw/4ff9c6c9-9674/k2-_cd6b8be4-8bfb-47bc-9843-49e8ed571106.v1.jpg?odnHeight=120&odnWidth=240&odnBg=FFFFFF", "Comp & Accessories"),
		GetItem("https://i5.walmartimages.com/dfw/4ff9c6c9-8370/k2-_15a0a4d2-1619-4914-94cd-774567d41404.v1.jpg?odnHeight=120&odnWidth=240&odnBg=FFFFFF", "Furniture"),
		GetItem("https://i5.walmartimages.com/dfw/4ff9c6c9-48f6/k2-_7aed4b13-f076-4785-8b0c-2a8343c2b70c.v1.jpg?odnHeight=120&odnWidth=240&odnBg=FFFFFF", "Fashion"),
		GetItem("https://i5.walmartimages.com/dfw/4ff9c6c9-f903/k2-_593c15ba-e773-49f3-9de3-d36778997619.v1.jpg?odnHeight=120&odnWidth=240&odnBg=FFFFFF", "Bath"),
		GetItem("https://i5.walmartimages.com/dfw/4ff9c6c9-49a1/k2-_34929c48-8885-4b5f-9448-b9abc7b04116.v1.jpg?odnHeight=120&odnWidth=240&odnBg=FFFFFF", "Sports"),
		GetItem("https://i5.walmartimages.com/dfw/4ff9c6c9-d172/k2-_03f75c9e-4b5c-4553-9439-4a9febfafab9.v1.jpg?odnHeight=120&odnWidth=240&odnBg=FFFFFF", "Bed"),
		GetItem("https://i5.walmartimages.com/dfw/4ff9c6c9-6897/k2-_9d771225-ddc0-4ae4-8302-1921a8ace961.v1.jpg?odnHeight=120&odnWidth=240&odnBg=FFFFFF", "Toys"),
	];

	return (
		<div>
			<h3 className="fs-5 fw-bold mb-4">Top Categories</h3>
			<Row>
				{items.map((x) => (
					<Col className="text-center">
						<div className="home-category  rounded-8 mx-auto mb-3">
							<img src={x.img} />
						</div>
						<p className="fw-bold font-md text-center">{x.title}</p>
					</Col>
				))}
			</Row>
		</div>
	);
};

export const LargeCategory = () => {
	const items = [
		{
			img: "computer.jpeg",
		},
		{
			img: "electronics.jpeg",
		},
		{
			img: "furniture.jpeg",
		},
		{
			img: "kitchen.jpeg",
		},
	];

	return (
		<div>
			<h1 className="text-dark fs-4 fw-bold">Let's Explore</h1>
			<Row>
				{items.map((x) => (
					<Col md="6 mb-4">
						<div className="home-category rounded-8">
							<img src={Blob_Base_Url + "/images/" + x.img} />
						</div>
					</Col>
				))}
			</Row>
		</div>
	);
};
